// Main JS File
require('../css/app.scss');
window.Popper = require('popper.js');
require('bootstrap');
require('bootstrap-table');
require('bootstrap-table/dist/locale/bootstrap-table-de-DE.js');

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
require('fullcalendar');
require('locale');

global.moment = require('moment');
require('tempusdominus-bootstrap-4');
const $ = require('jquery');
global.$ = global.jQuery = $;
require('qtip2');
require('summernote');
require('summernote-ext-print');

$(document).ready(function(){
//<!-- index youtube -->
  autoPlayYouTubeModal();
  //<!-- text editor options-->
  $('#summernote , #event_type_form_text').summernote({
        fontSizes: ['8', '9', '10', '11', '12', '14','16','18', '24', '36', '48' , '64', '82', '150'],
      toolbar: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize','hr']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph','table']],
          ['height', ['height']],
          ['misc', ['print']]
      ]
  });
});

$("#printInvoice").click(function(){
    printDiv('invoice');
});



function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.addEventListener("afterprint",function(){
        location.reload();
    })
    window.print();

    document.body.innerHTML = originalContents;
    $("#invoice-print").click(function(){
     printDiv('invoice-text');
    });
}

function autoPlayYouTubeModal(){
    var trigger = $("body").find('[data-toggle="modal"]');
    trigger.click(function() {
        var theModal = $(this).data( "target" ),
        videoSRC = $(this).attr( "data-theVideo" ),
        videoSRCauto = videoSRC;
        $(theModal+' iframe').attr('src', videoSRCauto);
        $('#videoModal').on('hidden.bs.modal', function () {
            $('#videoModal iframe').removeAttr('src');
        });
    });
}

window.onscroll = function() {scrollNav()};

var navbar = document.getElementById("navbar");

var sticky = navbar.offsetTop;

function scrollNav() {
    if (window.pageYOffset > sticky) {
        navbar.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
    }
}

$(document).ready(function() {
$('.eventtable').on('click-row.bs.table', function (e, arg1, arg2, arg3) {
  location.href = '/adminarea/booking/' + arg1[0];
})

  $('a.active').removeClass('active');
  $('a[href="' + location.pathname + '"]').addClass('active');
});

// Adminarea
// hide and show
// toggle "until input"
window.toggleHidden = function() {
    var x = document.getElementById("rewind-hidden");
    if (x.style.display === "none") {
        x.style.display = "block";
    };
};
$(document).ready(function(){
    $("#hide-add-guests").click(function(){
        $("#insertGuests").toggle();
        var text = $("#hide-add-guests").text();
        $("#hide-add-guests").text(
            text == '-' ? '+' : '-'
        );
    });
    $("#hide-add-events").click(function(){
        $("#insertEvents").toggle();
        var text = $("#hide-add-events").text();
        $("#hide-add-events").text(
            text == '-' ? '+' : '-'
        );
    });
    $("#hide-new-customers").click(function(){
        $("#new-customers-table").toggle();
        var text = $("#hide-new-customers").text();
        $("#hide-new-customers").text(
            text == '-' ? '+' : '-'
        );
    });
});

// change values on ajax_booking buttons
$(document).on('click', 'button.ajax', function(){

    dataset = $(this)[0].dataset;
    id = dataset.id;
    action = dataset.action;
    target = dataset.target;

    if(action == 'delete' || action == 'dissmiss'){
        var confirmed = confirm('Sicher? Ok führt ' + action + ' aus...' );
        if (confirmed == false){
            return false;
        };
    }

    if(action=='delete'){
    var body = '';
    } else {
    var body = $('div.note-editor.note-frame.panel.panel-default > div.note-editing-area > div.note-editable')[0].innerHTML;
    }

    // check for interfiering bookings
    if(target=='customer' && action=='confirm'){
        $.ajax({
            url:'/ajax/check',
            type: "POST",
            dataType: "json",
            data: {
                "id": id,
            },
            async: true,
            success: function (data)
            {
                if(data.error != null){
                    alert(data.error);
                    return false;
                };
                //no interfiering bookings
                $.ajax({
                    url:'/ajax/booking',
                    type: "POST",
                    dataType: "json",
                    data: {
                        "id": id,
                        "action": action,
                        "target": target,
                        "body": body
                    },
                    async: true,
                    success: function (data)
                    {
                        if(data.error != null){
                            alert(data.error);
                        };
                        if(data.output == 'success'){
                            location.href = '/newadminarea';
                        };
                    }
                });
            }
        });
    } else if (target=='booking' || action != 'confirm'){
        $.ajax({
            url:'/ajax/booking',
            type: "POST",
            dataType: "json",
            data: {
                "id": id,
                "action": action,
                "target": target,
                "body": body
            },
            async: true,
            success: function (data)
            {
                if(data.error != null){
                    alert(data.error);
                };
                if(data.output == 'success'){
                    location.href = '/newadminarea';
                };
            }
        });
    }

    return false;
});

//Pageination
$(function() {
    $('.page-link').click(function(e){
        var params = getPageParams();

        if (e.target.dataset.action == 'booking'){
            params['bookingPage'] = e.target.dataset.page;
        } else if (e.target.dataset.action == 'customer'){
            params['customerPage'] = e.target.dataset.page;
        };
        window.open(getParamString(params),"_self");

        e.preventDefault();
    });
});
function getPageParams(){
    var paramstr = window.location.search.substr(1,window.location.search.length);
    if( paramstr == '' ){
        return [];
    };
    var params = paramstr.split('&');
    var i,p = [];
    for (i  = 0; i < params.length; i++){
        params[i] = params[i].split('=');
        p[params[i][0]] = params[i][1];
    };
    return p;
};
function getParamString(params){
    if (params == []){
        return [];
    };

    var str = '?';
    for (param in params){
        str += param + '=' ;
        str += params[param] + '&';
    };
    str = str.slice(0,-1);
    return str;
};

// Calendar
// set price on change
$(function() {
    $('#inputBookingType').change(function(){
        var e = document.getElementById("inputBookingType");
        var val = e.options[e.selectedIndex].value;
        var text = e.options[e.selectedIndex].text;
        $('#bookingModal').find('#inputEventType').val(val);
        $('#bookingModal').find('#inputTitle').val(text);
        setPrice();
    });
    $('#inputCount').change(function() {
        setPrice();
    });
    $('.popover-dismiss').popover({
        placement: 'left',
        trigger: 'click,focus',
        html: true,
        content: function(){
            var eEvent = document.getElementById("inputEventType");
            var event = eEvent.value;
            if(event == null){return;}
            if (event == 'who'){
                return $('#whoPopup').html();
            } else if (event == 'durlach'){
                return $('#durlachPopup').html();
            } else if (event == 'quiz'){
                return $('#quizPopup').html();
            } else if (event == 'bus'){
                return $('#busPopup').html();
            } else if (event == 'dinner'){
                return $('#dinnerPopup').html();
            } else if (event == ''){
                return 'Bitte wähle erst eine Führung aus';
            }
        }
    });
});

function setPrice(){
    var eType = document.getElementById("inputType");
    var type = eType.value;

    var eEvent = document.getElementById("inputEventType");
    var event = eEvent.value;

    var eCount = document.getElementById('inputCount');
    var count = eCount.options[eCount.selectedIndex].value;

    if( type == null || event == '' || count == '' ){
        return;
    };

    $.ajax({
        url:'/ajax/booking/price',
        type: "POST",
        dataType: "json",
        data: {
            "type": type,
            "event": event,
            "count": count
        },
        async: true,
        success: function (data)
        {
            if (data.error != null){
                $('#bookingModal').find('#showPrice').text(data.error);
            } else {
                $('#bookingModal').find('#showPrice').text(data.price + ' €');
                $('#bookingModal').find('#bookingDuration').text('Dauer ca. ' + data.duration + ' Minuten');
            };
        }
    });
};

$(function () {
    $('#calendar-holder').fullCalendar({
        locale: 'de',
        themeSystem: 'bootstrap4',
        contentHeight:'auto',
        eventColor: '#576db6',
        defaultView: 'agendaWeek',
        slotDuration: '00:30:00',
        minTime: '08:00:00',
        maxTime: '21:00:00',
        timeFormat: 'H:mm',
        slotLabelFormat: 'H:mm',
        displayEventTime: true,
        allDayDefault: false,
        allDaySlot: false,
        navLinks: false,
        selectable: true,
        selectHelper: true,
        unselectAuto: true,
        selectOverlap: false,
        header: {
            left: 'prev, next, today',
            center: 'title',
            right: 'agendaWeek , listWeek , month'
        },
        selectConstraint: {
            // days of week. an array of zero-based day of week integers (0=Sunday)
            dow: [ 1, 2, 3, 4 , 5 , 6 , 0 ], // Monday - Sunday

            start: '09:00', // a start time (10am in this example)
            end: '20:00', // an end time (6pm in this example)
        },
        businessHours: {
          // days of week. an array of zero-based day of week integers (0=Sunday)
            dow: [ 1, 2, 3, 4 , 5 , 6 , 0 ], // Monday - Sunday

            start: '09:00', // a start time (10am in this example)
            end: '20:00', // an end time (6pm in this example)
        },
        lazyFetching: true,
        eventSources: [
            {
                url: '/fc-load-events',
                type: 'POST',
                data: {
                    filters: {}
                },
                error: function () {
                    alert('There was an error while fetching the calendar!');
                }
            }
        ],
        eventClick: function(event) {
            if ( event['start'] < moment() ) {
                alert('Dieses Datum liegt in der Vergangenheit');
                location.reload();
                return;
            };

            if (event['type'] == "public" ) {
                $('#bookingModal').modal('show');
                $('#bookingModal').find('#inputBookingId').val(event.id);

                $('#bookingModal').find('#inputEventType').val(event.event);
                $('#bookingModal').find('#inputTypeTitle').val('Öffentliche Führung');
                $('#bookingModal').find('#inputType').val('public');
                $('#bookingModal').find('#inputStarttime').val(event.start.format('H:mm') + ' Uhr');
                $('#bookingModal').find('#inputDate').val(event.start.format('DD.MM.YYYY'));

                $('#bookingModal').find('#inputTitle').val(event.title);
                $('#bookingModal').find('#inputTitle').removeAttr('hidden');

                $('#bookingModal').find('#inputBookingType').attr('hidden',true);
                $('#bookingModal').find('#inputBookingType').attr('required',false);
                setPrice();
            };
         },
        eventRender: function(event, element) {
            element.find('.fc-title').append("<br/>" + event.description);
            if (event['type'] == "public" && event['start'] > moment()) {
                element.qtip({
                    content: 'Klick zum Buchen! ;)',
                    hide: {
                        fixed: true,
                        delay: 500
                    }
                });
            };
        },
        selectAllow: function(selectInfo){
            if( selectInfo.start < moment().add(1, 'days') ){
                alert('Dieser Termin ist leider zu kurzfristig :(');
                $('#calendar-holder').fullCalendar('unselect');
                location.reload();
                return false;
            } else return true;
        },
        select: function( start, end, jsEvent, view ) {
            if(window.location.href.indexOf('adminarea') > -1 ){
                location.href = '/newadminarea/addevent?start=' + start.format('DD.MM.YYYY-H:mm') + '&end=' + end.format('DD.MM.YYYY-H:mm');
                return true;
            }
            $('#bookingModal').modal('show');
            $('#bookingModal').find('#inputTypeTitle').val('Private Führung');
            $('#bookingModal').find('#inputType').val('private');

            $('#bookingModal').find('#inputEventType').val(event.event);
            $('#bookingModal').find('#inputStarttime').val(start.format('H:mm') + ' Uhr');
            $('#bookingModal').find('#inputDate').val(start.format('DD.MM.YYYY'));


            $('#bookingModal').find('#inputTitle').val('');
            $('#bookingModal').find('#inputTitle').attr('hidden',true);

            $('#bookingModal').find('#inputBookingType').attr('hidden',false);
            $('#bookingModal').find('#inputBookingType').attr('required',true);
            setPrice();
         },
        dayClick: function(date, jsEvent, view) {

            if(view.name == 'month'){
                $('#calendar-holder').fullCalendar('changeView', 'agendaWeek',date);
                alert('Klicke in der Wochenansicht auf die gewünschte Stelle, um zu buchen.');
            }
         },
        views: {
            agendaWeek: {
                displayEventTime: false,
            }
        },
        buttonText:{
            today:    'Heute',
            month:    'Monat',
            week:     'Woche',
            day:      'Tag',
            list:     'Liste'
        },
        columnFormat: 'D.M'
    });
});

//add_events
// Datetimepicker stuff - sequence 2 1 3
$(function () {
    // until
    $('#datetimepicker3').datetimepicker({
        locale: 'de',
        format: 'DD.MM.YYYY H:mm',
        icons: {
                time: "fa fa-clock",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down"
            }
    });
    // start
    $('#datetimepicker2').datetimepicker({
        locale: 'de',
        format: 'DD.MM.YYYY H:mm',
        icons: {
                time: "fa fa-clock",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down"
            },
        enabledHours: [9,10,11,12,13,14,15,16,17,18,19,20],
    });
    // end
    $('#datetimepicker').datetimepicker({
        useCurrent: false,
        locale: 'de',
        format: 'DD.MM.YYYY H:mm',
        icons: {
                time: "fa fa-clock",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down"
            }
    });
    // startdate as min end
    $("#datetimepicker2").on("change.datetimepicker", function (e) {
        $('#datetimepicker').datetimepicker('minDate', e.date);
        setDateDiff($('#datetimepicker').datetimepicker('date'),e.date);
    });
    // end as min start
    $("#datetimepicker").on("change.datetimepicker", function (e) {
        $('#datetimepicker2').datetimepicker('maxDate', e.date);
        setDateDiff(e.date,$('#datetimepicker2').datetimepicker('date'));
    });
});

function setDateDiff(date1,date2){
    if ( date1 == null || date2 == null ) {
        return;
    };
    date1.seconds(0);
    date2.seconds(0);
    date1.milliseconds(0);
    date2.milliseconds(0);
    // set mins as help
    var mins = date1.diff(
        date2,
        'minutes',
        true
    );
    var minuteString;
    var hourString;
    if (mins > 60){
        var hours = parseInt(mins / 60);
        hours == 1 ? hourString = 'Stunde' : hourString = 'Stunden';

        mins = mins % 60;
        mins == 1 ? minuteString = 'Minute' : minuteString = 'Minuten';

        $('#minutes').val(hours + ' ' + hourString + ' ' + mins + ' ' + minuteString);
    } else {
        mins == 1 ? minuteString = 'Minute' : minuteString = 'Minuten';
        $('#minutes').val(mins + ' ' + minuteString);
    };



};



// toggle form options on type private
$(function() {
    $('#inputTypeAdmin').change(function(){
        var e = document.getElementById("inputTypeAdmin");
        var val = e.options[e.selectedIndex].value;

        var inputCollection = document.getElementById('privateOptions').getElementsByTagName('input');
        if ( val == 'private' ){
            $('#privateOptions').removeAttr('hidden');
            var i;
            for (i = 0; i < inputCollection.length; i++) {
                if(inputCollection[i].id.includes('tui_')){
                    continue;
                };
                inputCollection[i].required = 'true';
            }
        } else {
            $('#privateOptions').attr('hidden',true);
            var i;
            for (i = 0; i < inputCollection.length; i++) {
                inputCollection[i].required = false;
            }
        }
    });
});

// send data to ajax_events
$("#insertEvents").submit(function(e) {

    var form = $(this);
    // debug console.log(form.serialize());

    $.ajax({
           type: "POST",
           url:'/ajax/events',
           data: form.serialize(),
           success: function(data)
           {
                if(data.error != null){
                    alert(data.error);
                };
                if(data.output == 'success'){
                    location.href = '/adminarea';
                }
                if(data.output == 'sendmail'){
                    window.location.href = data.url;
                }
           }
         });

    e.preventDefault(); // avoid to execute the actual submit of the form.
});

// add_guest
$("#insertGuests").submit(function(e) {

    var form = $(this);
    // debug console.log(form.serialize());

    $.ajax({
    type: "POST",
    url:'/ajax/customer',
    data: form.serialize(),
    success: function(data)
    {
        console.log(data);
        if(data.error != null){
            alert(data.error);
        };

        if(data.output == 'success'){
            location.reload();
        };

        if(data.output == 'sendmail'){
            console.log('url' + data.url);
            window.location.href = data.url;
        };
    }
    });

    e.preventDefault(); // avoid to execute the actual submit of the form.
});

$('#new-customers-table').bootstrapTable({
  formatNoMatches: function ()
       {
       return 'Alle Aufträge bearbeitet! :)';
       }
})